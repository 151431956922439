<template>
  <div>
    <b-row class="mb-2">
      <b-card class="w-100 m-2 p-3" no-body>
        <div>
          <b-row align-v="center">
            <b-col>
              <h4 class="card-title mb-0 text-primary">{{ $t('SideBarItems.reports.list.reportCampaigns') }} /
                {{ $t('report.campaignList.detail.campaignDetail') }}</h4>
            </b-col>
            <b-col align="end">
              <b-button class="m-0" variant="primary" @click="toAllCampaigns"><i class="fas fa-arrow-left"></i>
                {{ $t('report.campaignList.detail.campaignAll') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-col lg="12" md="12" sm="12">
          <b-card class="mb-2">
            <b-row class="justify-content-center">
              <b-col cols="12" lg="12" md="12">
                <h4>{{ $t('report.campaignList.detail.overView') }}</h4>
                <hr class="p-0"/>
                <b-row align="center">
                  <b-col lg="6" md="12" sm="12">
                    <h6 class=" mb-0">
                      {{ $t('report.campaignList.detail.successRate') }}
                    </h6>
                    <div class="d-flex flex-column w-100 mb-3 mt-0">
                      <vue-apex-charts
                          type="radialBar"
                          :options="chartOptions"
                          :series="series"
                      ></vue-apex-charts>
                    </div>
                  </b-col>
                  <!--                  <b-col lg="6" md="12" sm="12">-->
                  <!--                    <h6 class=" mb-0">-->
                  <!--                      {{ $t('report.campaignList.detail.successRate') }}-->
                  <!--                    </h6>-->
                  <!--                    <div class="d-flex flex-column w-100 mt-0">-->
                  <!--                      <vue-apex-charts-->
                  <!--                          type="radialBar"-->
                  <!--                          :options="chartFailOptions"-->
                  <!--                          :series="seriesFail"-->
                  <!--                      ></vue-apex-charts>-->
                  <!--                    </div>-->
                  <!--                  </b-col>-->
                  <b-col lg="6" md="12" sm="12">
                    <h6>
                      {{ $t('report.campaignList.detail.totalCreditReturn') }}
                    </h6>
                    <label style="font-size: 48px"
                           class="mt-4 mb-0 text-info text-bold">{{ changeNumberFormat(totalCreditReturn) }}</label>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="12" md="12">
                <b-row>
                  <b-col
                      class="mb-2"
                      v-for="(totalincome, index) in totalincomes"
                      :key="index"
                      cols="12" lg="6" md="6" sm="12"
                  >
                    <div class="total-overview h-100" :class="[totalincome.bgcolor]">
                      <div class="d-flex align-items-center">
                        <div class="mr-2" style="width: 73px !important;">
                        <span
                            class="display-3 ml-4"
                            :class="[totalincome.iconcolor]"
                        >
                          <img :src="require('@/assets/images/icon/'+[totalincome.icon]+'.svg')"/>
                        </span>
                        </div>
                        <div>
                        <span :class="[totalincome.textcolor] " class="text text-dark">
                          {{ $t(totalincome.text) }}
                        </span>
                          <h3 class="font-medium mb-0 text-dark">
                            {{ changeNumberFormat(totalincome.total) }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="12" md="12" sm="12">
                    <b-row class="p-0 m-0 d-flex justify-content-end" v-if="total_failed_box!==0">
                      <b-col lg="6" md="12" sm="12">
                        <div v-show="!showMore" align="center" class="mt-2 action-button" @click="moreDetail"><span
                            class="text-danger"> {{ $t('subscriptions.customPackage.seeDetail') }} <i
                            class="fas fa-angle-down"></i> </span></div>
                      </b-col>
                    </b-row>
                    <div v-show="showMore" class="total-overview total-overview-red p-2 text-dark">
                      <b-row align-h="center" align-v="center">
                        <b-col class="p-0 m-0" lg="6" md="12" sm="12" align="center">
                          <div class="d-flex flex-column w-75">
                            <vue-apex-charts
                                type="donut"
                                :options="chartFailDetailOptions"
                                :series="seriesFailDetail"
                            ></vue-apex-charts>
                          </div>
                        </b-col>
                        <b-col lg="6" md="12" sm="12">
                          <b-row>
                            <b-col>
                              <h6>
                                {{ $t('report.campaignList.topic_fail') }}
                              </h6>
                            </b-col>
                          </b-row>
                          <b-row class="pl-2">
                            <b-col lg="12" md="12" sm="12">
                              <i class="fas fa-circle " style="color: #9f718e"></i>
                              {{ $t('report.campaignList.total_not_process') }} :
                              <span class="text-danger text-bold">{{ changeNumberFormat(total_not_process) }}</span>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                              <i class="fas fa-circle " style="color: #A50F15"></i>
                              {{ $t('report.campaignList.total_expired') }} : <span
                                class="text-danger text-bold">{{ changeNumberFormat(total_expired) }}</span>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                              <i class="fas fa-circle " style="color: #f52828"></i>
                              {{ $t('report.campaignList.total_blocked') }} : <span
                                class="text-danger text-bold">{{ changeNumberFormat(total_blocked) }}</span>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                              <i class="fas fa-circle " style="color: #f65c09"></i>
                              {{ $t('report.campaignList.total_blacklist') }} :
                              <span class="text-danger text-bold"> {{ changeNumberFormat(total_blacklist) }}</span>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                              <i class="fas fa-circle " style="color: #000000"></i>
                              {{ $t('report.campaignList.total_failed') }} :
                              <span class="text-danger text-bold"> {{ changeNumberFormat(total_only_failed) }}</span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="12" md="12" sm="12" class="h-100">
          <b-card class="mb-2">
            <h4>{{ $t('report.campaignList.detail.overViewTracking') }}</h4>
            <b-row class="justify-content-center" align="center">
              <b-col lg="6" md="12" sm="12" v-if="total_click === 0">
                <img class="m-3" src="@/assets/images/icon/click.svg"/>
                <div>
                  <label v-if="use_tag_url">{{ $t('report.campaignList.not_click') }}</label>
                  <label v-else>{{ $t('report.campaignList.not_use_track') }}</label>
                </div>
              </b-col>
              <b-col lg="12" md="12" sm="12" v-else>
                <b-row align-h="center" align-v="center">
                  <b-col class="p-0 m-0" lg="6" md="12" sm="12" align="center">
                    <div class="d-flex flex-column w-75">
                      <vue-apex-charts type="donut" :options="chartOptions2Pro"
                                       :series="chartSeries2Pro"></vue-apex-charts>
                    </div>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" align="left">
                    <b-row class="pl-2">
                      <b-col lg="12" md="12" sm="12">
                        <h4 class="text-info">{{ $t('dashboard.amounts') }}
                          {{ ((total_click / total_delivered) * 100).toFixed(1) }} %</h4>
                      </b-col>
                      <b-col lg="12" md="12" sm="6">
                        <i class="fas fa-circle " style="color: #28c76f"></i>
                        {{ $t('dashboard.sendSuccessful') }} :
                        <span>({{ changeNumberFormat(total_delivered) }})</span>
                      </b-col>
                      <b-col lg="12" md="12" sm="6">
                        <i class="fas fa-circle " style="color: #3CADDE"></i>
                        {{ $t('report.campaignList.total_click') }} : <span
                      >({{ changeNumberFormat(total_click) }})</span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <b-col lg="12" md="12" sm="12">
          <b-card class="mb-2">
            <b-row>
              <b-col>
                <h4>{{ $t('report.campaignList.detail.campaignDetail') }}</h4>
              </b-col>
              <b-col align="end">
                <div>
                  {{ $t('report.campaignList.detail.timeOfCreditReturned') }}
                </div>
                <h4 :class="credit_refund_status === 0 ? 'text-primary': 'text-blue'">
                <span
                    v-if="(total_waiting_dr !== 0 || total_failed !== 0) && (credit_refund_status === 0 || credit_refund_status !== 1  ) && credit_refund_status !== 4">
                  {{ formatTime(is_refund_time) }}
                </span>
                  <span v-else-if="credit_refund_status === 1|| credit_refund_status === 4">
                  {{ filterValue(refundStatusList, credit_refund_status) }}
                </span>
                  <span v-else>
                 -
                </span>
                </h4>
              </b-col>
            </b-row>
            <hr class="mt-0 p-0"/>
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-row
                    align-v="center"
                    class="align-items-center mb-2 "
                    v-for="(campaignDetail, index) in campaignList"
                    :key="campaignDetail.topic"
                >
                  <b-col lg="6" sm="12" class="text-left">
                    <label class="fw-medium mb-0 label-for-topic-over" :class="{'text-danger': index === 4}">
                      {{ $t(campaignDetail.topic) }}:
                    </label>
                  </b-col>
                  <b-col lg="6" md="12" sm="12" class="text-right">
                    <label class="fw-medium mb-0 p-0">
                    <span v-if="index === 1">
                      {{ campaignDetail.value }}
                      <i v-if="campaignDetail.value"
                         :id="campaignDetail.value"
                         v-b-tooltip.focus
                         :title="$t('common.copy')"
                         class="fas fa-copy ml-1 copy-text"
                         @click="copyCampaignId(campaignDetail.value)"/>
                    </span>
                      <b-badge pill v-else-if="index === 2"
                               :class="`px-2 text-white badge ${campaignDetail.value === 'normal' ? 'bg-info' :'bg-primary' }`">
                        {{ filterValue(typeList, campaignDetail.value) }}
                      </b-badge>
                      <b-badge pill v-else-if="index === 3" :class="colorButton(campaignDetail.value)"
                               class="px-2 text-white badge">
                        {{ filterValue(statusList, campaignDetail.value) }}
                      </b-badge>
                      <span v-else-if="index === 4" class="text-danger">
                      {{ campaignDetail.value }}
                    </span>
                      <span v-else-if="index === 6" class="text-primary">
                     {{
                          (total_waiting_dr !== 0 && total_failed !== 0 && credit_refund_status !== null) || (total_waiting_dr !== 0 || total_failed !== 0 && credit_refund_status !== null) ? campaignDetail.value : '-'
                        }}
                    </span>
                      <span v-else-if="index === 7">
                     <span v-if="total_waiting_dr !== 0 || (total_waiting_dr === 0 && campaignDetail.value === 3)">
                       {{ filterValue(refundStatusList, campaignDetail.value) }}
                     </span>
                      <span v-else-if="total_failed !== 0 && campaignDetail.value === 0">
                       {{ filterValue(refundStatusList, campaignDetail.value) }}
                     </span>
                       <span v-else-if="campaignDetail.value === 1">
                       {{ filterValue(refundStatusList, campaignDetail.value) }}
                     </span>
                       <span v-else-if="campaignDetail.value === 2">
                       {{ filterValue(refundStatusList, campaignDetail.value) }}
                     </span>
                      <span v-else>-</span>
                    </span>
                      <span v-else style="width: 200px; ">{{ campaignDetail.value }}</span>
                    </label>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <label
                    style="color: #ff3b3b;"
                    v-html="note"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="12" md="12" sm="12">
          <b-card class="mb-2">
            <b-row>
              <b-col lg="12" md="6" sm="12" class="mb-4" >
                <b-button
                    v-if="credit_refund_status !== 4"
                    pill block size="lg" variant="primary"
                    :disabled="!refundDisable || is_refund_time !== 0"
                          @click="onRefundCredit">
                  <i class="fas fa-plus-circle mr-2"></i>{{ $t('report.campaignList.detail.returnCredit') }}
                  <b-spinner small v-show="refundLoading && !refundDisable"/>
                </b-button>
              </b-col>
              <b-col lg="12" md="12" sm="12">
                <label v-html="refundNote"/>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
    <b-card>
      <campaign-contact-list :campaign_id="campaign_id" @find-contact="getCampaignDetail"
                             :prevent-retarget="prevent_retarget"
                             v-if="checkPermission('list-contact', 'action', 'campaign-reports')"></campaign-contact-list>
    </b-card>
  </div>
</template>
<script>
import TopicComponent from '@/components/common/topicComponent'
import VueApexCharts from 'vue-apexcharts'
import CampaignContactList from '@/views/reports/campaign/contactList'
import reportApi from "@/repository/reportApi";
import functionsCommon from "@/common/functions";
import alert from "@/common/alert";
import masterData from "@/common/masterData.json";
import authApi from "@/repository/authApi";
import {store} from "@/store";
export default {
  name: 'CampaignDetail',
  data: () => ({
    showMore: false,
    prevent_retarget: false,
    successRate: 0,
    campaign_id: null,
    totalCreditReturn: 0,
    refundStatus: '',
    refundExpire: '',
    refundDisable: false,
    refundLoading: false,
    credit_refund_expire_after_days: 0,
    credit_refund_start_after_hours: 6,
    total_click: 0,
    credit_refund_status: 0,
    total_delivered: 0,
    use_tag_url: false,
    typeList: masterData.campaignTypeList,
    refundStatusList: masterData.refundStatusDisplayInCampaignDetail,
    statusList: masterData.campaignStatusList,
    chartOptions2Pro: {},
    chartSeries2Pro: [],
    chartOptions: {},
    series: [0],
    chartFailOptions: {},
    seriesFail: [0],
    chartFailDetailOptions: {},
    seriesFailDetail: [0],
    campaignList: [
      {topic: 'report.campaignList.campaignName', value: ''},
      {topic: 'report.campaignList.campaignId', value: ''},
      {topic: 'report.campaignList.campaignType', value: ''},
      {topic: 'report.campaignList.status', value: ''},
      {topic: 'report.campaignList.detail.timeSendCampaign', value: ''},
      {topic: 'report.campaignList.detail.sendCampaignDate', value: ''},
      {topic: 'report.campaignList.creditReturnAvailableDate', value: '',},
      {topic: 'report.campaignList.detail.refundStatus', value: '',},
    ],
    total_failed_box: 0,
    total_blocked: 0,
    total_expired: 0,
    total_not_process: 0,
    total_waiting_dr: 0,
    total_blacklist: 0,
    total_only_failed: 0,
    totalincomes: [
      {
        bgcolor: 'total-overview-purple',
        iconcolor: '',
        icon: 'cam_total',
        textcolor: 'text-gray',
        text: 'report.campaignList.detail.recipients',
        total: 0,
      },
      {
        bgcolor: 'total-overview-yellow',
        iconcolor: '',
        icon: 'cam_waiting',
        textcolor: 'text-black',
        text: 'report.campaignList.total_waiting_dr',
        total: 0,
      },
      {
        bgcolor: 'total-overview-green',
        iconcolor: '',
        icon: 'cam_send',
        textcolor: 'text-gray',
        text: 'report.campaignList.detail.delivered',
        total: 0,
      },
      {
        bgcolor: 'total-overview-red',
        iconcolor: '',
        icon: 'cam_fail',
        textcolor: 'text-gray',
        text: 'report.campaignList.detail.failed',
        total: 0,
      },

    ],
    timer: null,
    timer_campaign: null,
    refund_time: 0,
    create_time: 0,
    is_refund_time: "",
    is_send_time: "",
    is_timer_campaign: false,
    total_failed: 0,
    campaign_status: '',
    credit_refund_status: ''
  }),
  components: {
    CampaignContactList,
    TopicComponent,
    VueApexCharts,
  },
  computed: {
    note() {
      return this.$t('report.campaignList.detail.note1') + this.credit_refund_expire_after_days + this.$t('report.campaignList.detail.note2')
    },
    refundNote() {
      const node2 = this.credit_refund_status == 4 ? this.$t('report.campaignList.detail.returnCreditNoteBlock') : this.$t('report.campaignList.detail.returnCreditNote2')
      return this.$t('report.campaignList.detail.returnCreditNote1') + this.credit_refund_start_after_hours + node2;
    }
  },
  methods: {
    moreDetail() {
      this.showMore = true
    },
    colorButton(data) {
      return functionsCommon.colorButtonStatusByText(data)
    },
    onRefundCredit() {
      this.refundLoading = true
      this.refundDisable = !this.refundDisable
      const data = {
        campaigns_id: this.campaign_id
      }
      reportApi.refundCredit(data).then(response => {
        if (response?.codeSYS === '001') {
          alert.refundSuccess(this)
          this.getCampaignDetail()
        } else {
          this.refundDisable = !this.refundDisable
          alert.refundFailed(this, response.message)
        }
      }).catch(err => {
        alert.refundFailed(this, err.response.data.message)
      }).finally(() => {
        this.getCredit()
        this.refundLoading = false
      })
    },
    getCredit() {
      authApi.getCustomerProfile(true).then(response => {
        if (response?.codeSYS === '001') {
          // const total = response?.sms_unit ? response?.sms_unit : 0
          // store.dispatch('profile/setCredit', total)
          store.dispatch('profile/setProfileImg', response?.image)
          // const url_tracking = response?.url_tracking ? response?.url_tracking : 0
          // store.dispatch('profile/setURLTracking', url_tracking)
          store.dispatch('profile/setName', response?.first_name + " " + response?.last_name)
        }
      })
    },
    changeNumberFormat(date) {
      return date ? functionsCommon.changeNumberFormat(date) : '0'
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    disableRetarget(temp_item) {
      const item = _.cloneDeep(temp_item)
      const total_delivered = item?.total_delivered ? item?.total_delivered : 0
      const total_failed = item?.total_failed ? item?.total_failed : 0
      const arr = [total_delivered, total_failed]
      const sum = arr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0,
      )
      this.prevent_retarget = sum === item?.recipients && item?.total_waiting_dr === 0
    },
    getCampaignDetail() {
      if (this.campaign_id) {
        this.chartOptions.labels = []
        reportApi.getCampaignById(this.campaign_id).then(resp => {
          this.disableRetarget(resp)
          this.credit_refund_expire_after_days = resp?.credit_refund_expire_after_days ? resp?.credit_refund_expire_after_days : 30
          this.credit_refund_start_after_hours = resp?.credit_refund_start_after_hours ? resp?.credit_refund_start_after_hours : 6
          this.campaignList[0].value = resp?.campaign_name ? resp?.campaign_name : '-'
          this.campaignList[1].value = resp?.campaign_id ? resp?.campaign_id : '-'
          this.campaignList[2].value = resp?.campaign_type ? resp?.campaign_type : '-'
          this.campaignList[3].value = resp?.campaign_status ? resp?.campaign_status : '-'
          this.campaignList[5].value = resp?.campaign_type === 'schedule' ? this.changeDateFormat(resp?.schedule_time) : this.changeDateFormat(resp?.createdAt)
          this.campaignList[6].value = this.changeDateFormat(resp?.credit_refund_date)
          this.campaignList[7].value = resp?.credit_refund_status
          this.refundDisable = resp?.is_refundable ? resp?.is_refundable : false
          this.totalincomes[0].total = resp?.recipients ? resp?.recipients : 0
          const total_delivered = resp?.total_delivered ? resp?.total_delivered : 0
          const total_click = resp?.total_click ? resp?.total_click : 0
          this.total_click = total_click
          this.credit_refund_status = resp?.credit_refund_status || null
          this.total_delivered = total_delivered
          this.totalincomes[1].total = resp?.total_waiting_dr ? resp?.total_waiting_dr : 0
          this.totalincomes[2].total = total_delivered
          this.totalincomes[3].total = resp?.total_failed ? resp?.total_failed : 0
          this.total_failed_box = resp?.total_failed ? resp?.total_failed : 0
          const chartValue = resp?.success_rate_percent ? [parseInt(resp?.success_rate_percent)] : [0]
          const labelData = chartValue + '%'
          let chart2ProValue = [total_delivered - total_click, total_click]
          this.successRate = resp?.success_rate_percent ? parseInt(resp?.success_rate_percent) : 0
          this.totalCreditReturn = resp?.total_credit_return ? resp?.total_credit_return : 0
          this.total_blacklist = resp?.total_blacklist ? resp?.total_blacklist : 0
          this.refundStatus = resp?.credit_refund_status
          this.total_blocked = resp?.total_blocked ? resp?.total_blocked : 0
          this.total_expired = resp?.total_expired ? resp?.total_expired : 0
          this.total_not_process = resp?.total_not_process ? resp?.total_not_process : 0
          this.total_waiting_dr = resp?.total_waiting_dr ? resp?.total_waiting_dr : 0
          this.total_only_failed = resp?.total_only_failed ? resp?.total_only_failed : 0
          let chartFailValue = [this.total_not_process, this.total_expired, this.total_blocked, this.total_blacklist, this.total_only_failed]
          this.writeFailDetailChart(chartFailValue)
          this.writeChartTracking2Pro(chart2ProValue)
          this.writeChart(labelData, chartValue)
          this.writeFailChart(labelData, chartValue)
          this.is_timer_campaign = resp?.is_campaign_timer
          this.use_tag_url = resp?.use_tag_url
          this.campaign_timer = resp?.is_campaign_timer ? this.timeToSeconds(resp?.campaign_timer) : resp?.campaign_timer
          if (this.campaign_timer) {
            this.startTimer()
          } else {
            this.campaignList[4].value = this.timeToSeconds(this.campaign_timer)
          }
          this.refund_time = resp?.credit_refund_date
          this.create_time = this.schedule_time_type === 'schedule' ? resp?.schedule_time : resp?.createdAt
          let current_time = new Date()
          this.total_failed = resp?.total_failed ? resp?.total_failed : 0
          this.campaign_status = resp?.campaign_status ? resp?.campaign_status : '-'
          this.credit_refund_status = resp?.credit_refund_status
          if (resp?.campaign_type !== 'schedule') {
            this.timer = setInterval(() => this.countDownRefundTime(), 1000)
          } else {
            if (current_time >= new Date(resp?.schedule_time)) {
              this.timer = setInterval(() => this.countDownRefundTime(), 1000)
            }
          }
        })
      }
    },
    writeChart(labelValue, chartValue) {
      this.chartOptions = {
        colors: ['#28c76f'],
        chart: {
          type: 'radialBar',
        },
        labels: [labelValue],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 50,
              size: '55%',
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: '250%',
                fontWeight: 600,
                color: '#28c76f',
              },
              value: {
                show: false,
              }
            },
          },
        },
      },
          this.series = chartValue
    },
    writeFailChart(labelValue, chartValue) {
      this.chartFailOptions = {
        colors: ['#f52828'],
        chart: {
          type: 'radialBar',
        },
        labels: [labelValue],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 50,
              size: '55%',
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: '250%',
                fontWeight: 600,
                color: '#f52828',
              },
              value: {
                show: false,
              }
            },
          },
        },
      },
          this.seriesFail = chartValue
    },
    writeFailDetailChart(chartValue) {
      this.chartFailDetailOptions = {
        colors: ['#9f718e', '#A50F15', '#f52828', '#f65c09', '#000000'],
        chart: {
          width: 240,
          type: 'pie',
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            customScale: 0.8,
            dataLabels: {
              offset: 25,
            },
          }
        },
        dataLabels: {
          enabled: true,
          position: 'top',
          offsetX: 30,
          style: {
            colors: ['rgba(0,0,0,0.96)'],
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          },
        },
        legend: {
          show: false
        }
      },
          this.seriesFailDetail = chartValue
    },
    writeChartTracking2Pro(chartValue) {
      this.chartOptions2Pro = {
        colors: ['#28c76f', '#3CADDE'],
        chart: {
          type: 'donut',
        },
        dataLabels: {
          enabled: true,
          position: 'top',
          offsetX: 30,
          style: {
            colors: ['rgba(0,0,0,0.96)'],
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          },
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false
        }
      }
      this.chartSeries2Pro = chartValue
    },
    checkPermission(permission, type, modules) {
      return functionsCommon.userCheckPermission(permission, type, modules)
    },
    toAllCampaigns() {
      const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(this.$route.query))
      const str = queryString.toString()
      const url_path = `/Reports/Campaigns?${str}`
      this.$router.push(url_path).catch(() => {
      })
    },
    countDownRefundTime() {
      let current_time = new Date()
      let refund_time = new Date(this.refund_time).getTime()
      let create_time = new Date(this.create_time).getTime()
      if (current_time >= refund_time || this.total_waiting_dr === 0 && this.total_failed === 0 || this.credit_refund_status === 'cancelled') {
        this.is_refund_time = 0
        clearInterval(this.timer);
      } else if (current_time < create_time) {
        this.is_refund_time = Math.floor((create_time - current_time) / 1000);
      } else if (this.is_refund_time === 0) {
        window.location.reload()
        clearInterval(this.timer);
      } else {
        this.is_refund_time = Math.floor((refund_time - current_time) / 1000);
        if (this.is_refund_time === 0) {
          clearInterval(this.timer);
          window.location.reload()
        }
      }
    },
    formatTime(seconds) {
      return functionsCommon.formatTime(seconds, this)
    },
    copyCampaignId(id) {
      this.$clipboard(id)
    },
    startTimer() {
      let elapsedTime = this.campaign_timer;
      this.timer_campaign = setInterval(() => {
        elapsedTime++;
        if (this.is_timer_campaign) {
          this.campaignList[4].value = this.secondsToTime(elapsedTime)
        } else {
          this.campaignList[4].value = this.campaign_timer + ' ' + this.$t('common.minute')
          clearInterval(this.timer_campaign);
        }

      }, 1000);
    },
    timeToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    },
    secondsToTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(remainingSeconds)} ${this.$t('common.minute')}`;
    },
    padNumber(number) {
      return String(number).padStart(2, "0");
    }

  },
  created() {
    this.campaign_id = this.$route.query.campaign
    // if (this.campaign_id) {
    //   this.getCampaignDetail()
    // }

  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer_campaign);
  },
  watch: {}
}
</script>
<style>
.action-button {
  cursor: pointer;
}

.total-overview {
  color: #fff;
  border-radius: 10px;
  padding: 3px;
  background-repeat: repeat-x;
}


.total-overview-green {
  background-color: #E7F6EC;
  /*background-image: linear-gradient(30deg, rgba(40, 199, 111, 0.5), #28c76f);*/
}

.total-overview-red {
  background-color: #FFE9E9;
  /*background-image: linear-gradient(30deg, rgba(229, 84, 85, 0.98), rgba(234, 84, 85, 0.5));*/
}

.total-overview-yellow {
  background-color: #FFF6E8;
  /*background-image: linear-gradient(30deg, #fffd54, rgba(225, 99, 83, 0.5));*/
}


.total-overview-purple {
  background-color: #d7d3f6;
  /*background-image: linear-gradient(30deg, #7367f0, rgba(115, 103, 240, 0.5));*/
}

.text-gray {
  color: rgba(229, 228, 228, 0.98);
}

.display-3 {
  font-size: 2.75rem;
}

.label-for-topic-over {
  color: #0d73d9;
}

.copy-text {
  cursor: pointer;

  &
  :hover {
    color: #19CBAC;
  }

}
</style>
