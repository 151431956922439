<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <h4>{{ $t("contacts.menu.contacts") }}</h4>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col lg="3" md="3" sm="4">
                <b-form-group
                    label-cols="12"
                    :label="$t('contacts.create.phone')"
                    label-for="input-sm"
                >
                  <b-form-input v-model="form.phone"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="4">
                <select-filter-component v-model="form.status" :options="statusList"
                                         :label-select="$t('report.campaignList.contactList.status')"/>

              </b-col>
              <b-col lg="3" md="3" sm="4">
                <select-filter-component v-model="form.status_tracking" :options="trackingStatusMaster"
                                         :label-select="$t('report.campaignList.status_tracking')"/>

              </b-col>
              <b-col style="margin-top: 35px">
                <button
                    type="button"
                    class="btn waves-effect waves-light btn-gradient"
                    @click="findContact"
                >
                  {{ $t("common.Search") }}
                </button>
                <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
                  {{ $t('common.resetSearch') }}
                </button>
              </b-col>
            </b-row>
            <hr/>
            <b-row align-v="center" class="pb-3">
              <b-col>
                <div class="d-flex align-items-center">
                  <span class="mr-3">{{ $t('common.perPage') }}</span>
                  <div>
                    <b-form-select
                        v-model="form.$limit"
                        :options="pageOptions"
                        size="sm"
                        @change="findContact"
                    />
                  </div>
                  <div class="ml-3">
                    <total-list-component :total="totalAll"/>
                  </div>
                </div>
              </b-col>
              <b-col align="end" class="mb-2">
                <b-button variant="primary" class="mr-2" @click="exportContact"
                          v-if="checkPermission('export-contact', 'action', 'campaign-reports')"><i
                    class="fas fa-file-excel "></i>
                  {{ $t("button.export") }}
                </b-button>
                <b-button variant="primary" @click="reTargetModal(campaign_id)"
                          v-if="preventRetarget && checkPermission('create', 'action', 'send-using-file')">
                  <img :src="require('@/assets/images/icon/re-target.svg')">
                  <span class="ml-2">Retarget</span>
                </b-button>
              </b-col>
            </b-row>
            <b-table
                responsive
                class="mb-0"
                :items="items"
                :fields="fields"
                show-empty
                :busy="isBusy"
                :empty-text="$t('empty')"
            >
              <template #head()="{ label, field: { key, sortable }}">
                {{ $t(label) }}
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong> {{ $t('loading') }}</strong>
                </div>
              </template>
              <template #empty="scope">
                <empty-table/>
              </template>
              <template #cell(status_gateway)="data">
                {{ filterValue(smsGWStatusList, data.item.status_gateway_desc) }}
              </template>
              <template #cell(status_dr)="data">
                <b-badge pill :class="colorButton(data.item.status)" class="px-2 pt-1 text-white badge ">
                  {{ filterValue(smsStatusList, data.item.status) }}
                </b-badge>
              </template>
              <template #cell(status_tracking)="data">
                <b-badge pill :class="colorTrackingButton(data.item.status_tracking)"
                         class="px-2 pt-1 text-white badge ">
                  {{ filterValue(trackingStatusMaster, data.item.status_tracking ? data.item.status_tracking : 0) }}
                </b-badge>
              </template>
              <template #cell(createdAt)="data">
                {{ changeDateFormat(data.item.createdAt) }}
              </template>
              <template #cell(cost)="data">
                <label class="text-bold text-blue" style="min-width: 60px"> {{ data.item.cost }}</label>
              </template>
              <template #cell(send_date)="data">
                {{ changeDateFormat(data.item.send_date) }}
              </template>
              <template #cell(action)="data">
                <i style="color: #0d73d9" class="action-button icon-eye mr-2" @click="viewDetail(data.item._id)"/>
              </template>
            </b-table>
          </b-col>
          <div class="ml-auto mt-4">
            <b-pagination
                v-model="form.$skip"
                :total-rows="totalRows"
                :per-page="form.$limit"
                align="fill"
                class="my-0"
                size="sm"
                @input="findContact"
            />
          </div>
        </b-row>
      </b-card>
    </b-col>
    <contact-detail :sms-detail="smsDetail"/>
    <b-modal
        id="modal-export"
        ok-variant="info"
        hide-footer
        no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5></h5>
        <i class="fas fa-times hover-icon action-button" @click="close"></i>
      </template>
      <div align="center" v-if="!exportSuccess">
        <b-spinner
            class="mt-5 mb-1"
            style="width: 4rem; height: 4rem"
            label="Large Spinner"
            variant="warning"
            type="grow">
        </b-spinner>
        <h3 v-html="$t('processing')" class="mb-3"></h3>
      </div>
      <div v-if="exportSuccess">
        <div align="center" v-if="exportSuccess&&step_success">
          <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
          <h3 v-html="$t('success')" class="mb-3"></h3>
          <div v-for="items in fileExport">
            <a :href="items">{{ items }}</a>
            <hr/>
          </div>
        </div>
        <div align="center" v-if="!exportSuccess&&step_success">
          <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2"/>
          <h3 v-html="$t('failed')" class="mb-3"></h3>
        </div>
      </div>
    </b-modal>
    <retarget :loading="reTargetLoading" :obj="returnReTarget"/>
  </b-row>
</template>

<script>

import SelectFilterComponent from "@/components/common/selectFilterComponent";
import SwitchComponent from "@/components/common/switchComponent";
import functionsCommon from "@/common/functions";
import _ from "lodash";
import alert from "@/common/alert";
import reportApi from "@/repository/reportApi";
import functions from "@/common/functions";
import masterStatus from "@/common/masterStatusData.json";
import masterData from "@/common/masterData.json";
import ContactDetail from "@/views/reports/campaign/contactDetail";
import {validatePhoneNumber} from "@sms2pro/sms2pro";
import EmptyTable from "@/components/common/emptyTable.vue";
import Retarget from "@/views/reports/campaign/retarget.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "campaignContactList",
  components: {TotalListComponent, Retarget, EmptyTable, ContactDetail, SelectFilterComponent, SwitchComponent},
  props: {
    campaign_id: {
      type: [Number, String]
    }, preventRetarget: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    totalAll: 0,
    form: {
      status: null,
      status_tracking: null,
      phone: '',
      $skip: 1,
      $limit: 10,
    },
    returnReTarget: null,
    reTargetLoading: false,
    isBusy: false,
    exportSuccess: false,
    step_success: false,
    contact_id: '',
    fileExport: [],
    smsDetail: {},
    statusList: masterData.campaignStatus,
    smsStatusList: masterData.smsStatusList,
    smsGWStatusList: masterData.smsGWStatusList,
    fields: [
      {
        key: "createdAt",
        label: "report.campaignList.deliveredAt",
      },
      {
        key: "sender_name",
        label: "report.campaignList.contactList.from",
      },
      {
        key: "to",
        label: "report.campaignList.contactList.to",
      },
      {
        key: "cost",
        label: "report.campaignList.contactList.cost",
        class: "text-center"
      },
      {
        key: "status_gateway",
        label: "report.campaignList.contactList.statusGateway",
      },
      {
        key: "status_dr",
        label: "report.campaignList.contactList.status",
      },
      {
        key: "status_tracking",
        label: "report.campaignList.status_tracking",
      },
      {
        key: "action",
        label: "report.campaignList.action",
      },
    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
    gatewayMaster: masterStatus.gatewayStatusList,
    trackingStatusMaster: masterStatus.trackingStatusList,
    smsMaster: masterStatus.smsStatusList,
  }),
  methods: {
    reTargetModal(id) {
      this.reTargetLoading = true
      this.$bvModal.show('modal-retarget-campaign')
      reportApi.getCampaignById(id).then(resp => {
        this.returnReTarget = resp
      }).catch(() => {
        this.$bvModal.hide('modal-retarget-campaign')
      }).finally(() => {
        this.reTargetLoading = false
      })
    },
    colorButton(data) {
      return functionsCommon.colorButtonStatus(data)
    },
    colorTrackingButton(data) {
      switch (data) {
        case 0:
          return '';
        case 1:
          return 'bg-success-light';
        case 2:
          return 'bg-danger-light';
        default:
          return
      }
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    validatePhoneNumber(data) {
      const phone = data ? validatePhoneNumber(data) : ''
      return phone ? phone : data
    },
    viewDetail(data) {
      reportApi.findMessageDetail(data).then(response => {
        if (response?.codeSYS === '001') {
          this.smsDetail = response
          this.$bvModal.show('modal-contact-detail')
        }
      }).catch(err => {
        alert.findFailed(this, err.response.data.message)
      })
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    exportContact() {
      this.$bvModal.show('modal-export')
      const data = {
        status: this.form.status,
        campaign_id: this.campaign_id,
        phone: this.validatePhoneNumber(this.form.phone),
        status_tracking: this.form.status_tracking,
        file_type: "csv"
      }
      reportApi.exportCampaignContact(data).then(response => {
        if (response?.codeSYS === '001') {
          this.fileExport = response?.url_files
          this.exportSuccess = true
          this.step_success = true
        } else {
          this.exportSuccess = false
          this.step_success = false
        }
      }).catch(err => {
        this.$bvModal.hide('modal-export')
        alert.findFailed(this, err.response.data.message)
      })
    },
    findContact() {
      this.isBusy = !this.isBusy
      this.form.phone = this.validatePhoneNumber(this.form.phone)
      reportApi.campaignContact(this.campaign_id, _.cloneDeep(this.form)).then(response => {
        this.totalRows = response.total
        this.totalAll = response.total
        this.items = response.data
      }).catch(err => {
         throw err
      }).finally(() => {
        this.isBusy = !this.isBusy
        this.$emit('find-contact', true)
      })
    },
    resetSearch() {
      this.form = {
        status: null,
        status_tracking: null,
        to: '',
        $skip: 1,
        $limit: 10,
      }
      this.findContact()
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  },
  mounted() {
    setTimeout(() => {
      this.findContact()
    }, 0);
  },

};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
