<template>
  <div class="empty-file text-center">
    <img :src="require('@/assets/images/icon/empty-folder.svg')" class="empty-file-icon" :width="80"
         :height="80" alt="empty-table"/>
    <div class="mt-2">
      {{$t('common.no_data_table')}}
    </div>
  </div>
</template>
<script>

export default {
  name: 'empty-table',
}
</script>
<style scoped>
.empty-file {
  padding: 4rem;
  color: var(--secondary-color);
}
</style>