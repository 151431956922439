<template>
  <label class="toggle mt-2" :class="lang==='vn'?'vn-style':''">
    <input type="checkbox" :disabled="disabledType==='true'" :value="value" v-model="value" @input="updateValue"
           @change="changeValue">
    <span class="slider"></span>
    <span class="labels" :data-on="labelOn ? labelOn : $t('common.active')"
          :data-off="labelOff ? labelOff : $t('common.inactive')"></span>
  </label>
</template>

<script>
export default {
  name: "switchComponent",
  computed: {
    lang() {
      return this.$i18n.locale
    }
  },
  props: {
    value: {
      type: [Boolean, Number],
      request: false,
      default: false
    },
    disabledType: {
      type: String,
      request: false,
      default: "false"
    },
    labelOn: {
      type: String,
      request: false,
    },
    labelOff: {
      type: String,
      request: false,
    },
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e.target.checked)
    },
    changeValue(e) {
      this.$emit('change', e.target.checked);
    }
  }
}
</script>

<style scoped>
.toggle {
  --width: 65px;
  --height: calc(var(--width) / 3.5);
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer;
}

.vn-style {
  --width: 85px !important;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: #ccc;
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--height));
  height: calc(var(--height));
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked + .slider {
  background-color: var(--primary-color);
}

.toggle input:checked + .slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {

  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 9px;
  font-family: "Prompt";
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 5px;
  color: #4d4d4d;
  opacity: 1;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 5px;
  color: #ffffff;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked ~ .labels::after {
  opacity: 0;
}

.toggle input:checked ~ .labels::before {
  opacity: 1;
}
</style>