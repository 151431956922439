<template>
  <b-form-group label-cols="12" label-for="input-sm">
    <template #label>
      <div v-if="info&&labelSelect" style="display: flex; gap: 8px; cursor: pointer">
        <p class="m-0">{{ labelSelect }}</p>
        <slot></slot>
      </div>
      <div v-else>
        {{ labelSelect }}
      </div>
    </template>
    <b-form-select
        :value="value"
        :v-model="value"
        @input="updateValue"
        :options="options"
        :text-field="field"
    >
      <template #first v-if="!block_first_template">
        <b-form-select-option :value="null">{{ $t('common.all') }}</b-form-select-option>
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: "selectFilterComponent",
  props: {
    info: {
      type: Boolean,
      default: false
    },
    block_first_template: {
      type: Boolean,
      default: false
    },
    labelSelect: String,
    options: Array,
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: null
    },
    fieldText: {
      type: String,
      required: false,
    }
  },
  computed: {
    field() {
      return this.fieldText ? this.fieldText : (this.$i18n.locale ? this.$i18n.locale : 'en')
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event)
    },
  },
}
</script>


<style>
.vs__dropdown-toggle {
  border-radius: 8px !important;
}
</style>