<template>
  <b-modal
      id="modal-retarget-campaign"
      ok-variant="info"
      size="lg"
      hide-footer
      no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <div class="flex-col-center w-100">
      <span class="w-100 flex-row-a-center justify-content-between">
        <h5 class="m-0 p-0">Retarget</h5>
        <i class="fas fa-times hover-icon" @click="close()"></i>
      </span>
        <hr class="w-100 m-0" style="margin-top: 8px !important;"/>
      </div>
    </template>
    <div align="center" v-if="loading">
      <b-spinner
          class="mt-5 mb-1"
          style="width: 4rem; height: 4rem"
          label="Large Spinner"
          variant="warning"
          type="grow">
      </b-spinner>
      <h3 v-html="$t('processing')" class="mb-3"></h3>
    </div>
    <div class="retarget-campaign-container" v-if="!loading">
      <div class="retarget-card" v-if="!threeWay">
        <p class="m-0 title">{{ $t('retarget.sendAll') }}</p>
        <p class="m-0 text-bold retarget-card__number">{{ obj?.recipients }}</p>
      </div>
      <div class="retarget-campaign-container__target">
        <div class="retarget-card w-100" v-if="threeWay">
          <p class="m-0 title">{{ $t('retarget.totalSendSuccess') }}</p>
          <p class="m-0 text-bold retarget-card__number">{{ obj?.recipients }}</p>
          <button class="btn-primary text-white" :class="{'button-disable':zeroAll}"
                  @click="sendAllRetarget" :disabled="zeroAll">
            {{ `Retarget ${$t('retarget.sendAll')}` }}
          </button>
        </div>
        <div class="retarget-card w-100">
          <p class="m-0 title">{{ $t('retarget.totalSendSuccess') }}</p>
          <p class="m-0 text-bold retarget-card__number text-success">{{ obj?.total_delivered }}</p>
          <button class=" btn-success" :class="{'button-disable':zeroSuccess}"
                  @click="sendSuccessRetarget" :disabled="zeroSuccess">
            {{ `Retarget ${$t('retarget.totalSendSuccess')}` }}
          </button>
        </div>
        <div class="retarget-card w-100">
          <p class="m-0 title">{{ $t('retarget.totalSendFail') }}</p>
          <p class="m-0 text-bold text-danger retarget-card__number">{{ obj?.total_failed }} </p>
          <button class=" btn-danger" :class="{'button-disable':zeroFail}" @click="sendFailRetarget"
                  :disabled="zeroFail">
            {{ `Retarget ${$t('retarget.totalSendFail')}` }}
          </button>
        </div>
      </div>
      <div class="retarget-campaign-container__detail">
        <p class="m-0 title">{{ $t('invoice.detail') }}</p>
        <p class="m-0 detail">{{`- ${$t('retarget.detailOne')}` }}</p>
        <p class="m-0 detail">{{`- ${$t('retarget.detail')}` }}</p>
        <ul class="p-0" style="padding-left: 28px !important;">
          <li class="m-0 detail">{{ $t('retarget.onlySuccess') }}</li>
          <li class="m-0 detail">{{ $t('retarget.onlyFail') }}</li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>
<script>
import router from "@/router/Router";
import reportApi from "@/repository/reportApi";

export default {
  name: 'campaignRetarget',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    obj: {
      type: [Object, Array],
      default: null
    }
  },
  data() {
    return {
      threeWay: false
    }
  }, computed: {
    zeroSuccess() {
      return this.obj?.total_delivered === 0
    },
    zeroFail() {
      return this.obj?.total_failed === 0
    },
    zeroAll() {
      return this.obj?.recipients === 0
    }
  },
  methods: {
    routeToSMS(title, status = null) {
      this.$swal.fire({
        icon: 'warning',
        title: title,
        text: this.$t('retarget.modalTitle'),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.send'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          const arg = {
            campaign_id: this.obj?.campaign_id,
            status: status
          }
          reportApi.reTarget(arg).then(resp => {
            sessionStorage.setItem('_a_menu_sms', 'sendUsingFile')
            localStorage.setItem("temp_retarget", JSON.stringify(resp))
            router.push({name: 'SMS'})
            this.close()
          }).catch(() => {
            localStorage.setItem("temp_retarget", false)
            this.close()
          })
        }
      })
    },
    sendAllRetarget() {
      this.routeToSMS(`${this.$t('common.confirm')} Retarget ${this.$t('retarget.sendAll')} ?`)
    },
    sendSuccessRetarget() {
      this.routeToSMS(`${this.$t('common.confirm')} Retarget ${this.$t('retarget.totalSendSuccess')} ?`, 'success')
    },
    sendFailRetarget() {
      this.routeToSMS(`${this.$t('common.confirm')} Retarget ${this.$t('retarget.totalSendFail')} ?`, 'fail')
    },
    close() {
      this.$bvModal.hide('modal-retarget-campaign')
    }
  }
}
</script>
<style lang="scss">
#modal-retarget-campaign {
  .btn-success, .btn-danger, .btn-primary {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2) !important;
    border-radius: 30px;
    padding: 8px 12px;
    transition: 0.2s;
    border: 0px
  }

  .button-disable {
    opacity: 0.5 !important;
    cursor: not-allowed;
  }

  .modal-header {
    padding-bottom: 0;
  }

  .flex-row-a-center {
    display: flex;
    align-items: center;
  }

  .flex-col-center {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .retarget-campaign-container {
    display: flex;
    gap: 16px;
    flex-direction: column;

    .title {
      font-size: 16px;
    }

    .detail {
      color: #9F9F9F;
      font-size: 14px;
    }

    .retarget-card {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: space-between;
      border-radius: 10px;
      box-shadow: 0px 1px 6px 1px rgb(0 0 0 / 0.1);


      .retarget-card__number {
        font-size: 24px;
      }
    }

    .retarget-campaign-container__target {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }

    .retarget-campaign-container__detail {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
</style>