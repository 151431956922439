<template>
  <div class="d-flex align-items-center">
    <span class="m-0" style="white-space: nowrap">{{ `${$t('common.totalAll')}  ` }}</span>
    <span class="custom-show ml-2">{{ total }}</span>
  </div>

</template>

<script>
export default {
  name: "totalListComponent",
  props: {
    total: {
      type: [String, Number],
      default: 0
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-show {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 12px !important;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 8px !important;
  color: #586a8f !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>