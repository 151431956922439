<template>
  <b-modal
      id="modal-contact-detail"
      ok-only
      ok-title="OK"
      modal-class="modal-primary"
      no-close-on-backdrop
      centered
      title=""
  >
    <template #modal-header="{ close }">
      <div></div>
      <i class="fas fa-times hover-icon action-button" @click="close"/>
    </template>
    <b-card-text>
      <b-row class="py-3  align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{ $t('report.messageList.senderId') }} :</label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0"> {{ smsDetail.sender_name }}</label>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{ $t('report.messageList.to') }} : </label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0 text-primary"> {{ smsDetail.to }}</label>
        </b-col>
      </b-row>
      <b-row class="py-3  align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0" w> {{ $t('report.messageList.message') }} :</label>
        </b-col>
        <b-col sm="8">
          <div style="width:270px!important; overflow: auto ">
            <p class="fw-medium mb-0" v-html="smsDetail.message"></p>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{ $t('report.messageList.status') }} : </label>
        </b-col>
        <b-col sm="8">
          <div class="fw-medium mb-0 text-primary">
            <div v-if="smsDetail?.res_dr?.code">
              {{ smsDetail?.res_dr?.code }}
              {{ smsDetail?.res_dr?.detail }}
            </div>
            <div v-else-if="!smsDetail?.res_dr?.code && smsDetail?.status === 7" class="text-warning">
              {{ 'Waiting DR' }}
            </div>
            <div v-else>
              -
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right ">
          <label class="fw-medium mb-0"> {{ $t('report.messageList.statusGateway') }} : </label>
        </b-col>
        <b-col sm="8">
          <div style="width:270px!important; overflow: auto ">
            <label class="fw-medium mb-0 ">{{ smsDetail?.res_gateway?.code }}
              {{ smsDetail?.res_gateway?.detail }}</label>
          </div>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center ">
        <b-col sm="4" class="text-left text-md-right ">
          <label class="fw-medium mb-0"> {{ $t('report.campaignList.status_tracking') }} : </label>
        </b-col>
        <b-col sm="8">
          <b-badge pill :class="colorTrackingButton(smsDetail?.status_tracking)" class="px-2 pt-1 text-white badge ">
            {{ filterValue(trackingStatusMaster, smsDetail?.status_tracking ? smsDetail?.status_tracking : 0) }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right ">
          <label class="fw-medium mb-0"> {{ $t('short_link.list.full_url') }} : </label>
        </b-col>
        <b-col sm="8">
          {{smsDetail?.url_tracking_detail?.main_link}}
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center ">
        <b-col sm="4" class="text-left text-md-right ">
          <label class="fw-medium mb-0"> {{ $t('short_link.list.short_url') }} : </label>
        </b-col>
        <b-col sm="8">
          {{smsDetail?.url_tracking_detail?.short_link}}
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{ $t('report.messageList.cost') }} : </label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0 text-primary text-green"> {{ smsDetail.cost }}</label>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center ">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{ $t('report.messageList.countSMS') }} : </label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0 text-green"> {{ smsDetail.cost }}</label>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center bg-light">
        <b-col sm="4" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{ $t('report.campaignList.total_click') }} : </label>
        </b-col>
        <b-col sm="8">
          <label class="fw-medium mb-0 text-green"> {{ smsDetail?.url_tracking_detail?.total_click }}</label>
        </b-col>
      </b-row>

      <b-row>
        <b-table
            responsive
            class="mb-0"
            :items="smsDetail?.url_tracking_detail?.logs"
            :fields="fields"
            show-empty
            :busy="isBusy"
            :empty-text="$t('empty')"
        >
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(no)="data">
            {{ data.index+1 }}
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
          <template #cell(os)="data">
            <label class="text-green"> {{ data.item?.os }}</label>
          </template>
          <template #cell(model)="data">
            <label class="text-green"> {{ data.item?.model }}</label>
          </template>
        </b-table>
      </b-row>
    </b-card-text>
  </b-modal>
</template>
<script>


import functionsCommon from "@/common/functions";
import masterData from "@/common/masterData.json";
import reportApi from "@/repository/reportApi";
import alert from "@/common/alert";
import masterStatus from "@/common/masterStatusData.json";
import EmptyTable from "@/components/common/emptyTable";

export default {
  name: "contactDetail",
  components: {EmptyTable},
  data() {
    return {
      smsStatusList: masterData.smsStatusList,
      trackingStatusMaster: masterStatus.trackingStatusList,
      isBusy: false,
      fields: [
        {
          key: "no",
          label: "report.campaignList.no",
        },
        {
          key: "createdAt",
          label: "report.campaignList.click_at",
        },
        {
          key: "os",
          label: "report.campaignList.click_on",
        } ,
        {
          key: "model",
          label: "report.campaignList.model",
        }
      ],
      items: [],
    }
  },
  props: {
    smsDetail: {
      type: Object,
      required: false
    }
  },
  methods: {
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    colorTrackingButton(data) {
      switch (data) {
        case 0:
          return '';
        case 1:
          return 'bg-success-light';
        case 2:
          return 'bg-danger-light';
        default:
          return
      }
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
  },
}
</script>

<style scoped>

</style>