import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = '/report'
export default {
    getInvoiceAll(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth.get(`${resourcePath}/invoice/search?${str}`).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    getDashboard(search = {}) {
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth.get(`dashboard?${str}`).then((resp) => {
            return resp.data;
        })
            .catch((err) => {
                throw err;
            });
    },
    getCreditTopUpSumary(search = {}) {
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/credit-topup/summary?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getCreditTopUpAll(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/credit-topup/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getCreditUsedAll(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/credit-used/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getCampaignAll(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/campaign/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    campaignContact(id, search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/campaign/contacts/${id}?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    exportCampaignContact(data) {
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(data)
        );
        return service.apiAuth.post(`${resourcePath}/campaign/contacts-export`, queryString)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    findMessageDetail(id) {
        return service.apiAuth.get(`${resourcePath}/message-detail/get/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    exportCampaign(data, search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth.post(`${resourcePath}/campaign/export?${str}`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    cancelCampaign(data) {
        return service.apiAuth.post(`/campaigns/cancel`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getCampaignById(id) {
        return service.apiAuth.get(`${resourcePath}/campaign/get/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    refundCredit(data) {
        return service.apiAuth.post(`/campaigns/refund`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getOtpAll(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/sms-otp/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getCreditUsedSumary(search) {
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/credit-used/summary?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getMessageAll(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/send-message/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getReturnCreditAll(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/credit-refund/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getSessionReportList(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`/customer/login-log/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    exportAdvancedReport(search) {
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth.get(`${resourcePath}/advance-export?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getAdvancedReport(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/advance?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    }, getCampaignAllRefund() {
        return service.apiAuth
            .get(`/campaigns/list-refund`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    sendCampaignAllRefund(data) {
        return service.apiAuth
            .post('/campaigns/refund-many', data)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    reTarget(data) {
        return service.apiAuth
            .post('/campaigns/retarget', data)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getSettingBannerList () {
        return service.apiAuth.get(`/banner-dashboard/list`).then(resp => {
            return resp.data
        }).catch((err) => {
            throw err
        })
    }
};